
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Nelson - New Zealand | Kiwi & Cacahuate" description="Visting my parents in Nelson for New years, and traveling to the Abel Tasman National Park." url="https://kiwicacahuate.com/nelson/" image="https://kiwicacahuate.com/nelson/nelson.jpg" imageWidth="3000" imageHeight="2000" />


    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="001">
              <source srcSet="/nelson/thumbnail/001.webp, /nelson/thumbnail/001-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/001.jpg, /nelson/thumbnail/001-2x.jpg 2x" />
              <img src="/nelson/thumbnail/001.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="002">
              <source srcSet="/nelson/thumbnail/002.webp, /nelson/thumbnail/002-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/002.jpg, /nelson/thumbnail/002-2x.jpg 2x" />
              <img src="/nelson/thumbnail/002.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="003">
              <source srcSet="/nelson/thumbnail/003.webp, /nelson/thumbnail/003-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/003.jpg, /nelson/thumbnail/003-2x.jpg 2x" />
              <img src="/nelson/thumbnail/003.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="004">
              <source srcSet="/nelson/thumbnail/004.webp, /nelson/thumbnail/004-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/004.jpg, /nelson/thumbnail/004-2x.jpg 2x" />
              <img src="/nelson/thumbnail/004.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="005">
              <source srcSet="/nelson/thumbnail/005.webp, /nelson/thumbnail/005-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/005.jpg, /nelson/thumbnail/005-2x.jpg 2x" />
              <img src="/nelson/thumbnail/005.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="006">
              <source srcSet="/nelson/thumbnail/006.webp, /nelson/thumbnail/006-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/006.jpg, /nelson/thumbnail/006-2x.jpg 2x" />
              <img src="/nelson/thumbnail/006.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="007">
              <source srcSet="/nelson/thumbnail/007.webp, /nelson/thumbnail/007-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/007.jpg, /nelson/thumbnail/007-2x.jpg 2x" />
              <img src="/nelson/thumbnail/007.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="008">
              <source srcSet="/nelson/thumbnail/008.webp, /nelson/thumbnail/008-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/008.jpg, /nelson/thumbnail/008-2x.jpg 2x" />
              <img src="/nelson/thumbnail/008.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="009">
              <source srcSet="/nelson/thumbnail/009.webp, /nelson/thumbnail/009-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/009.jpg, /nelson/thumbnail/009-2x.jpg 2x" />
              <img src="/nelson/thumbnail/009.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="010">
              <source srcSet="/nelson/thumbnail/010.webp, /nelson/thumbnail/010-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/010.jpg, /nelson/thumbnail/010-2x.jpg 2x" />
              <img src="/nelson/thumbnail/010.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="011">
              <source srcSet="/nelson/thumbnail/011.webp, /nelson/thumbnail/011-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/011.jpg, /nelson/thumbnail/011-2x.jpg 2x" />
              <img src="/nelson/thumbnail/011.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="012">
              <source srcSet="/nelson/thumbnail/012.webp, /nelson/thumbnail/012-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/012.jpg, /nelson/thumbnail/012-2x.jpg 2x" />
              <img src="/nelson/thumbnail/012.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="014">
              <source srcSet="/nelson/thumbnail/014.webp, /nelson/thumbnail/014-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/014.jpg, /nelson/thumbnail/014-2x.jpg 2x" />
              <img src="/nelson/thumbnail/014.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="015">
              <source srcSet="/nelson/thumbnail/015.webp, /nelson/thumbnail/015-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/015.jpg, /nelson/thumbnail/015-2x.jpg 2x" />
              <img src="/nelson/thumbnail/015.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="016">
              <source srcSet="/nelson/thumbnail/016.webp, /nelson/thumbnail/016-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/016.jpg, /nelson/thumbnail/016-2x.jpg 2x" />
              <img src="/nelson/thumbnail/016.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="017">
              <source srcSet="/nelson/thumbnail/017.webp, /nelson/thumbnail/017-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/017.jpg, /nelson/thumbnail/017-2x.jpg 2x" />
              <img src="/nelson/thumbnail/017.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="018">
              <source srcSet="/nelson/thumbnail/018.webp, /nelson/thumbnail/018-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/018.jpg, /nelson/thumbnail/018-2x.jpg 2x" />
              <img src="/nelson/thumbnail/018.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="019">
              <source srcSet="/nelson/thumbnail/019.webp, /nelson/thumbnail/019-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/019.jpg, /nelson/thumbnail/019-2x.jpg 2x" />
              <img src="/nelson/thumbnail/019.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="020">
              <source srcSet="/nelson/thumbnail/020.webp, /nelson/thumbnail/020-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/020.jpg, /nelson/thumbnail/020-2x.jpg 2x" />
              <img src="/nelson/thumbnail/020.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="021">
              <source srcSet="/nelson/thumbnail/021.webp, /nelson/thumbnail/021-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/021.jpg, /nelson/thumbnail/021-2x.jpg 2x" />
              <img src="/nelson/thumbnail/021.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="022">
              <source srcSet="/nelson/thumbnail/022.webp, /nelson/thumbnail/022-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/022.jpg, /nelson/thumbnail/022-2x.jpg 2x" />
              <img src="/nelson/thumbnail/022.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="023">
              <source srcSet="/nelson/thumbnail/023.webp, /nelson/thumbnail/023-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/023.jpg, /nelson/thumbnail/023-2x.jpg 2x" />
              <img src="/nelson/thumbnail/023.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="024">
              <source srcSet="/nelson/thumbnail/024.webp, /nelson/thumbnail/024-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/024.jpg, /nelson/thumbnail/024-2x.jpg 2x" />
              <img src="/nelson/thumbnail/024.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="025">
              <source srcSet="/nelson/thumbnail/025.webp, /nelson/thumbnail/025-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/025.jpg, /nelson/thumbnail/025-2x.jpg 2x" />
              <img src="/nelson/thumbnail/025.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="026">
              <source srcSet="/nelson/thumbnail/026.webp, /nelson/thumbnail/026-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/026.jpg, /nelson/thumbnail/026-2x.jpg 2x" />
              <img src="/nelson/thumbnail/026.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="027">
              <source srcSet="/nelson/thumbnail/027.webp, /nelson/thumbnail/027-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/027.jpg, /nelson/thumbnail/027-2x.jpg 2x" />
              <img src="/nelson/thumbnail/027.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="028">
              <source srcSet="/nelson/thumbnail/028.webp, /nelson/thumbnail/028-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/028.jpg, /nelson/thumbnail/028-2x.jpg 2x" />
              <img src="/nelson/thumbnail/028.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="029">
              <source srcSet="/nelson/thumbnail/029.webp, /nelson/thumbnail/029-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/029.jpg, /nelson/thumbnail/029-2x.jpg 2x" />
              <img src="/nelson/thumbnail/029.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="030">
              <source srcSet="/nelson/thumbnail/030.webp, /nelson/thumbnail/030-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/030.jpg, /nelson/thumbnail/030-2x.jpg 2x" />
              <img src="/nelson/thumbnail/030.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="031">
              <source srcSet="/nelson/thumbnail/031.webp, /nelson/thumbnail/031-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/031.jpg, /nelson/thumbnail/031-2x.jpg 2x" />
              <img src="/nelson/thumbnail/031.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="032">
              <source srcSet="/nelson/thumbnail/032.webp, /nelson/thumbnail/032-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/032.jpg, /nelson/thumbnail/032-2x.jpg 2x" />
              <img src="/nelson/thumbnail/032.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="033">
              <source srcSet="/nelson/thumbnail/033.webp, /nelson/thumbnail/033-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/033.jpg, /nelson/thumbnail/033-2x.jpg 2x" />
              <img src="/nelson/thumbnail/033.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="034">
              <source srcSet="/nelson/thumbnail/034.webp, /nelson/thumbnail/034-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/034.jpg, /nelson/thumbnail/034-2x.jpg 2x" />
              <img src="/nelson/thumbnail/034.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="035">
              <source srcSet="/nelson/thumbnail/035.webp, /nelson/thumbnail/035-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/035.jpg, /nelson/thumbnail/035-2x.jpg 2x" />
              <img src="/nelson/thumbnail/035.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="036">
              <source srcSet="/nelson/thumbnail/036.webp, /nelson/thumbnail/036-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/036.jpg, /nelson/thumbnail/036-2x.jpg 2x" />
              <img src="/nelson/thumbnail/036.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="037">
              <source srcSet="/nelson/thumbnail/037.webp, /nelson/thumbnail/037-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/037.jpg, /nelson/thumbnail/037-2x.jpg 2x" />
              <img src="/nelson/thumbnail/037.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"><picture data-name="038">
              <source srcSet="/nelson/thumbnail/038.webp, /nelson/thumbnail/038-2x.webp 2x" />
              <source srcSet="/nelson/thumbnail/038.jpg, /nelson/thumbnail/038-2x.jpg 2x" />
              <img src="/nelson/thumbnail/038.jpg" alt="Kiwi cachuate images" />
            </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

